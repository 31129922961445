<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Registra una sucursal
        </h3>
        <p class="mb-1 ml-2">
          Completa la información necesaria para poder registrar la sucursal
        </p>
      </v-col>
      <v-col cols="12">
        <v-card class="card_background">
          <v-container class="px-5">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="8" xl="8" class="pb-0">
                <v-form ref="branchOffice">
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        outlined
                        type="text"
                        ref="name"
                        v-model="branchOffice.branch_office_name"
                        :rules="[requiredRules]"
                        label="Nombre de la sucursal"
                        @keyup.enter.native="$refs.street.focus()"
                      ></v-text-field>
                    </v-col>
                    <h3 class="text-subtitle-1 pl-4">
                      Ubicación
                    </h3>
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        outlined
                        type="text"
                        ref="street"
                        v-model="branchOffice.location.street_name"
                        :rules="[requiredRules]"
                        label="Calle"
                        @keyup.enter.native="$refs.reference.focus()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        outlined
                        type="text"
                        ref="reference"
                        v-model="branchOffice.location.reference"
                        :rules="[requiredRules]"
                        label="Referencias"
                        @keyup.enter.native="$refs.streetNumber.focus()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="py-0">
                      <v-text-field
                        outlined
                        type="number"
                        ref="streetNumber"
                        v-model="branchOffice.location.street_number"
                        :rules="[requiredRules]"
                        label="Numero Exterior"
                        @keyup.enter.native="$refs.postalCode.focus()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="py-0">
                      <v-text-field
                        outlined
                        type="number"
                        ref="postalCode"
                        v-model="branchOffice.location.postal_code"
                        :rules="[requiredRules]"
                        label="Código postal"
                        @keyup.enter.native="$refs.city.focus()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="py-0">
                      <v-text-field
                        outlined
                        type="text"
                        ref="city"
                        v-model="branchOffice.location.city_name"
                        :rules="[requiredRules]"
                        label="Ciudad"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="py-0">
                      <v-select
                        v-model="branchOffice.location.state_name"
                        :rules="[requiredRules]"
                        ref="state"
                        :items="getStates"
                        label="Estado"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-col cols="12" class="text-right pt-0">
                <v-btn color="success" @click="saveBranchOffice"
                  >Agregar sucursal</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required } from "@/services/ValidatorService";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      requiredRules: required,
      branchOffice: {
        branch_office_name: null,
        location: {
          street_number: null,
          street_name: null,
          city_name: null,
          state_name: null,
          postal_code: null,
          reference: null
        }
      }
    };
  },
  methods: {
    ...mapActions("business", ["registerBranchOffice"]),
    async saveBranchOffice() {
      if (this.$refs.branchOffice.validate()) {
        const branchOfficeData = {
          branch_office_name: this.branchOffice.branch_office_name,
          location: this.branchOffice.location
        };
        await this.registerBranchOffice(branchOfficeData);
        this.$router.push({ name: "branchOfficeSettings" });
      }
    }
  },
  computed: {
    ...mapGetters("app", ["getStates"])
  }
};
</script>
